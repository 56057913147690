import Fuse from 'fuse.js'
import { deserialize } from 'bson'

const search = document.getElementById('search');
const load = document.getElementById('load');
const results = document.getElementById('results');

let searcher;

(async () => {
	let db = deserialize(await (await fetch('/sound.db')).arrayBuffer());
	window.db = db;
	let search = [];

	for (let key of Object.keys(db)) {
		search.push({
			hash: key,
			path: db[key]['Path']
		})
	}

	searcher = new Fuse(search, {
		keys: ['path', 'hash'],
	});
})()
.then(() => {
	load.hidden = true;
	
	search.onkeyup = async () => {
		let value = search.value;
		if (value.length < 3) return;
		const searchResults = searcher.search(search.value).slice(0, 20);

		const display = searchResults.map(result => {
			const displayResult = document.createElement('div');
			displayResult.classList.add('result');

			const path = document.createElement('a');
			path.href = 'javascript:void(0);'

			let seen = false;
			path.onclick = () => {
				if (seen) return;
				seen = true;

				const audio = document.createElement('audio');
				audio.controls = true;
				audio.src = `/sounds/${result.item.hash}.mp3`;
				displayResult.appendChild(audio);

				const download = document.createElement('a');
				download.classList.add('download');
				download.appendChild(document.createTextNode('Download'));
				download.href = audio.src;
				displayResult.appendChild(download);

				displayResult.appendChild(document.createElement('hr'));
			}

			path.classList.add('path');
			path.appendChild(document.createTextNode(result.item.path));
			displayResult.appendChild(path);

			const hash = document.createElement('span');
			hash.classList.add('hash');
			hash.appendChild(document.createTextNode(` (${result.item.hash})`));
			displayResult.appendChild(hash);

			displayResult.appendChild(document.createElement('hr'));

			return displayResult;
		})

		results.replaceChildren(...display);	
	}
})
